import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';

import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { GameMode, bonusIds } from '../../global.d';
import { setCurrency, setSlotConfig, slotHistoryGql } from '../../gql';
import { IBet, ISlotHistory, ISlotHistoryData } from '../../gql/d';
import { countCoins, normalizeCoins, showCurrency } from '../../utils';

import styles from './history.module.scss';

const renderKey = (item: ISlotHistory, key: string) => {
  if (key === 'date') {
    return new Date(item[key]).toLocaleString('en-US', { hour12: true });
  }

  if (key === 'bet') {
    return item[key];
  }

  return item[key];
};

const formatHistory = (historyData: ISlotHistoryData['edges']) => {
  const history = _.map(
    _.map(historyData, (elem) => ({
      ...elem.node,
      cursor: elem.cursor,
    })),
    (elem) => {
      const date = _.get(elem, 'createdAt', Date.now());
      const coins = countCoins({
        coinValue: _.get(elem, 'coinValue', 1),
        coinAmount: _.get(elem, 'coinAmount', 1),
      });
      const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: showCurrency(setCurrency()) });
      const win = formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(elem.result.winCoinAmount, _.get(elem, 'coinValue', 1)),
        showCurrency: showCurrency(setCurrency()),
      });

      return {
        date,
        win,
        bet,
        gameId: elem.id,
        cursor: elem.cursor,
        userBonusId: elem.userBonus?.bonusId || '',
      };
    },
  );

  return _.reverse(history);
};

const HistoryComponent: React.FC = () => {
  const { head, showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState<{ cursor: string; node: IBet }[]>([]);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');
  // const historyDevice = { isMobileDevice: false };

  const { data: historyData, fetchMore } = useQuery<
    { bets: ISlotHistoryData },
    {
      input: {
        first?: number;
        last?: number;
        before?: string;
        after?: string;
        filter?: { slotId?: string };
      };
    }
  >(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    onCompleted(res) {
      setHistory(res.bets.edges);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      if (history.length) {
        cursorLastRef.current = history[history.length - 1].cursor;
        cursorFirstRef.current = history[0].cursor;
      }
    }
  }, [history, historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles.history}>
        <div className={styles.spinner} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles.history}>
      <div className={styles.table}>
        <table className={styles.historyTable}>
          <thead>
            <tr>
              {Object.entries(head).map(([key, value]) => (
                <th key={key}>{t(value)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {formatHistory(history).map((item) => (
              <tr key={item.gameId}>
                {Object.keys(head).map((key) => (
                  <td key={key}>
                    <>
                      {renderKey(item, key)}
                      {key === 'bet' &&
                        item.userBonusId &&
                        item.userBonusId === bonusIds[GameMode.FREE_ROUND_BONUS] && (
                          <div className={styles.freeBet}>{t('freeRound')}</div>
                        )}
                    </>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {pageInfo.count > showBy && (
          <div className={styles.pagination}>
            <Button
              disabled={currentPage === 1}
              className={styles.pagination__btn}
              key="first"
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then((res) => {
                  setHistory(res.data.bets.edges);
                  setCurrentPage(1);
                });
              }}
            >
              <BiFirstPage />
            </Button>
            <Button
              disabled={currentPage === 1}
              className={`${styles.pagination__btn} ${!isMobile ? styles.hover : styles.active}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: showBy,
                      after: cursorLastRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then((res) => {
                  setHistory(res.data.bets.edges);
                  setCurrentPage(Math.max(1, currentPage - 1));
                });
              }}
            >
              <BiChevronLeft />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={`${styles.pagination__btn} ${!isMobile ? styles.hover : styles.active}`}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      last: showBy,
                      before: cursorFirstRef.current,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then((res) => {
                  setHistory(res.data.bets.edges);
                  setCurrentPage(Math.min(pageAmount, currentPage + 1));
                });
              }}
            >
              <BiChevronRight />
            </Button>
            <Button
              disabled={currentPage === pageAmount}
              className={styles.pagination__btn}
              onClick={() => {
                fetchMore({
                  variables: {
                    input: {
                      first: ((pageInfo.count - 1) % showBy) + 1,
                      filter: { slotId: setSlotConfig().id },
                    },
                  },
                }).then((res) => {
                  setHistory(res.data.bets.edges);
                  setCurrentPage(pageAmount);
                });
              }}
            >
              <BiLastPage />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(HistoryComponent);
