import { ApolloQueryResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import { BonusStatus, EventTypes, GameMode, IAuthInput, IUserBalance, UserBonus, bonusIds } from '../../global.d';
import {
  authGql,
  client,
  configGql,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  setBottomContainerTotalWin,
  setCurrency,
  setCurrentBonus,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setPrevReelsPosition,
  setProgress,
  setReplayBet,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  slotHistoryGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { loadErrorHandler, loadPixiAssets, parseQuery, wait } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const [isShowContent, setShowContent] = useState(false);
  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const { replayBetId } = parseQuery<IAuthInput>();
    if (replayBetId) {
      setReplayBet(replayBetId);
    }
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const checkBrokenGame = async () => {
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      // its locally for testing.
      // const userBonusData: ApolloQueryResult<{
      //   userBonuses: UserBonus[];
      // }> = JSON.parse(JSON.stringify(userBonusData1));

      // mock
      // userBonusData.data.userBonuses.push({
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 350000,
      //   coinAmount: 1,
      //   coinValue: 1000,
      //   id: '704806e0-0efd-4dde-8f43-7171658fd8fd',
      //   bonusId: '704806e0-0efd-4dde-8f43-7171658fd8fd',
      // });

      if (userBonusData.data.userBonuses.length > 0) {
        const freeRoundBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusIds[GameMode.FREE_ROUND_BONUS],
        );
        if (freeRoundBonus) {
          const frbTotalAmount = freeRoundBonus.totalWinAmount
            ? freeRoundBonus.totalWinAmount / freeRoundBonus.coinValue
            : 0;
          const win = (frbTotalAmount * freeRoundBonus.coinValue) / 100;
          setCurrentBonus({
            ...(freeRoundBonus as UserBonus),
            isActive: true,
            gameMode: GameMode.FREE_ROUND_BONUS,
            currentRound: 0,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: win,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            id: freeRoundBonus.id,
          });
          if (win) {
            setBottomContainerTotalWin(win);
          }
        }
      }
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
        setPrevReelsPosition(betsData.data.bets.edges[0].node.result.reelPositions);
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache<IConfig>('config', {
          isSkipIntroScreen: setSkipIntroScreen,
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
        });
        eventManager.emit(EventTypes.SKIP_INTRO_SCREEN);

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.CUSTOM, async (stage) => {
        await getUserBalance();
        await getLastBet();
        await checkBrokenGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, setSkipIntroScreen(), isSoundOn, setIsSuspended).then(
          () => {
            eventManager.emit(
              EventTypes.SOUND_INITIALIZED,
              AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
            );
          },
        );

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;

  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
