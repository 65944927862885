/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef } from 'react';

import { EventTypes, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getSlotGql,
  getUserGql,
  setBetAmount,
  setCoinValue,
  setCurrentBonus,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setPrevReelsPosition,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { ISlotData2 } from '../../slotMachine/d';
import { apiV2ToApiV1GetSlots, findSubstituteCoinAmount, wrap } from '../../utils';

import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';

const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  const [getSlot, { data }] = useLazyQuery<{ slot: ISlotData2 }, { input: { id: string } }>(getSlotGql, {
    variables: { input: { id } },
    onCompleted({ slot }) {
      const data = apiV2ToApiV1GetSlots(slot);
      SlotMachine.initSlotMachine(data, wrap(setIsSpinInProgress, false), wrap(setIsSlotBusy, false));
      // Overriding these methods to avoid certified files.
      SlotMachine.getInstance().throwTimeoutError = () => {
        if (!setIsRevokeThrowingError()) {
          setIsTimeoutErrorMessage(true);
          setIsErrorMessage(true);
        }
        eventManager.emit(EventTypes.THROW_ERROR);
      };
      slotMachine.current = SlotMachine.getInstance();
      const lines = data.lines.map((_, index) => index);
      const {
        settings: {
          playerSettings: { betAmount },
        },
      } = data;
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: data.clientSettings,
        lines,
        icons: data.icons,
      });

      const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
      const bonusCoinAmount = findSubstituteCoinAmount(
        setCurrentBonus().coinAmount,
        slot.clientSettings.coinAmounts.default,
      );
      if (setCurrentBonus().isActive) {
        setCoinValue({ ...setCoinValue(), variants: [setCurrentBonus().coinValue] });
      }
      const coinAmount = findSubstituteCoinAmount(
        setCurrentBonus().isActive ? bonusCoinAmount : lastBetCoinAmount,
        slot.clientSettings.coinAmounts.default,
      );

      setBetAmount(coinAmount);
    },
  });

  useQuery<{ user: IUserBalance }>(getUserGql, {
    onCompleted({
      user: {
        balance: { currency },
      },
    }) {
      setSlotConfig({
        ...setSlotConfig(),
        coinCode: currency,
      });
      getSlot();
    },
  });

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit('resize', width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      const application = slotMachine.current.getApplication();
      pixiContainerRef.current?.appendChild(slotMachine.current.getApplication().view);
      resize(application)();
      window.addEventListener('resize', resize(application));
      return () => window.removeEventListener('resize', resize(application));
    }
    return undefined;
  }, [!!data]);

  useEffect(() => {
    eventManager.emit('disablePayTable', isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit('disableAllMiniPayTables');
    }
  }, [isMiniPayTable]);

  return <div className={styles.canvasWrapper} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
