import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Popup } from '@phoenix7dev/shared-components';
import { goToLobby, lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { ModalOpeningTypes } from '../../global.d';
import { configGql, setIsModalOpeningInProgress, setIsOpenMenuModal } from '../../gql';
import { IConfig } from '../../gql/d';
import { queryParams } from '../../utils';
import History, { HistoryIcon } from '../History';
import Info, { InfoIcon } from '../Info';
import Settings, { SettingIcon } from '../Settings/SettingsMenu';

import styles from './menu.module.scss';

const MenuIcon: React.FC<{
  showLobbyBtn: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ showLobbyBtn, setShowPopup, setType, show }) => {
  const handleClosePopup = (): void => {
    setTimeout(() => {
      if (!AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) && !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    });
    setShowPopup(false);
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className={styles['menu-wrap']}>
        <div className={styles.item}>
          <InfoIcon setType={setType} />
        </div>
        <div className={styles.item}>
          <SettingIcon setType={setType} />
        </div>
        {showLobbyBtn && (
          <div className={styles.item}>
            <Button intent="lobby" onClick={() => goToLobby(queryParams)} />
          </div>
        )}
        <div className={styles.item}>
          <HistoryIcon setType={setType} />
        </div>
      </div>
      <Button intent="close" className="popup__close" onClick={handleClosePopup} />
    </div>
  );
};

const MenuComponent: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const [showPopup, setShowPopup] = useState(false);
  const [type, setType] = useState<'menu' | 'info' | 'settings' | 'history'>('menu');
  const { isOpenMenuModal } = data!;

  const handleClose = () => {
    if (setIsModalOpeningInProgress() && setIsModalOpeningInProgress() !== ModalOpeningTypes.MENU) return;

    setTimeout(() => {
      if (!AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) && !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenMenuModal({ isOpen: false, type: 'menu' });
  };

  useEffect(() => {
    if (isOpenMenuModal.isOpen) {
      AudioHowl.stop({ type: ISongs.SFX_UI_Close });
      AudioHowl.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setType(isOpenMenuModal.type);
    setShowPopup(isOpenMenuModal.isOpen);
  }, [isOpenMenuModal]);

  useEffect(() => {
    if (showPopup && data?.isSpinInProgress) {
      setShowPopup(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
    }
  }, [data?.isSpinInProgress, showPopup]);

  return (
    <Popup id="popup" className="popup popup_menu" showNow={showPopup} setShowNow={handleClose}>
      <MenuIcon
        showLobbyBtn={lobbyBtnAvailable(queryParams)}
        setShowPopup={handleClose}
        setType={setType}
        show={type === 'menu'}
      />
      <Info setType={setType} show={type === 'info'} />
      <Settings setType={setType} show={type === 'settings'} />
      <History setType={setType} show={type === 'history'} />
    </Popup>
  );
};

export default MenuComponent;
